<template>
  <div id="system" :class="[_isMobile ? 'mobile' : 'pc']">
    <navigation v-if="$route.meta.navigator" />
    <div
      class="view-contain"
      :class="{
        'no-header': !$route.meta.navigator,
      }"
    >
      <router-view />
    </div>
  </div>
</template>
<script>
import navigation from "./components/navigation";
export default {
  components: { navigation },
  computed: {
    _isMobile() {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },
  },
  created() {
    const userInfo = this.$storage.getStorage("userInfo");
    if (userInfo) {
      this.$store.commit("setUser", userInfo);
    }
  },
  mounted() {
    // console.log(this._isMobile);
  },
};
</script>
<style lang="less">
#system {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.pc {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-image: url(./assets/imgs/home-bg.png);
  .view-contain {
    position: absolute;
    top: 50px;
    bottom: 0;
    width: 100%;
    overflow: auto;
  }
  .no-header {
    top: 0 !important;
  }
}

.mobile {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-image: url(./assets/imgs/mobile-bg.png);
  .view-contain {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}
.echoPass {
  width: 100%;
  .echoPassTitle {
    font-size: 18px;
    text-align: center;
    color: #4caf50;
  }
  .echoErrorTitle {
    font-size: 18px;
    text-align: center;
    color: #f56c6c;
  }
  .echoPassContent {
    text-align: center;
    padding: 20px 0;
  }
  img {
    max-width: 100%;
  }
}
</style>
