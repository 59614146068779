<template>
  <div class="navigation">
    <div class="logo">
      <img src="../assets/imgs/logo.png" alt="" />
    </div>
    <!-- <div class="navigation-bar" v-if="$route.path != '/home'"> -->
    <div class="navigation-bar">
      <div
        class="bar-item"
        v-for="(i, _i) in navigation"
        :key="_i"
        @click="into(i.path)"
        :class="{ active: $route.path.indexOf(i.path) != -1 }"
      >
        {{ i.name }}
      </div>
    </div>
    <div class="userInfo">
      {{ userInfo.name }}
      <el-popover placement="bottom" trigger="click" style="text-align: center">
        <span style="cursor: pointer" @click="logout">退出登录</span>
        <template #reference>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </template>
      </el-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: "navigation",
  data() {
    return {
      userInfo:
        (localStorage.getItem("userInfo") &&
          JSON.parse(localStorage.getItem("userInfo"))) ||
        null,
      navigation: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "我的课程",
          path: "/curriculum",
        },
        {
          name: "我的考试",
          path: "/examination",
        },
        {
          name: "我的证书",
          path: "/certificate",
        },
      ],
    };
  },
  methods: {
    logout() {
      this.$https.post("/api/logout").then((res) => {
        localStorage.removeItem("userInfo");
        this.$router.push({
          path: "/login",
        });
      });
    },
    into(path) {
      this.$router.push({
        path: path,
      });
    },
  },
  mounted() {
    if (!this.userInfo) {
      this.$router.push({
        path: "/login",
      });
    }
  },
};
</script>

<style lang="less" scoped>
.navigation {
  position: absolute;
  width: 100%;
  height: 50px;
  background-color: #fff;
  z-index: 1;
  text-align: center;
  font-size: 13px;
  .logo {
    position: absolute;
    height: 50px;
    img {
      height: 100%;
    }
  }
  .navigation-bar {
    min-width: 400px;
    width: 50%;
    color: #677897;
    display: inline-block;
    margin-top: 18px;
    .bar-item {
      font-size: 18px;
      font-weight: bold;
      display: inline-block;
      height: 26px;
      margin: 0 4%;
      cursor: pointer;
    }
    .active {
      color: #0a73c5;
      border-bottom: 3px solid #0a73c5;
    }
  }
  .userInfo {
    line-height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    .el-icon--right {
      padding: 20px 20px 20px 0;
      cursor: pointer;
    }
  }
}
</style>
