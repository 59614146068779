import axios from "axios";
import router from "./router";
import storage from "./assets/js/storage";
import { ElMessageBox } from "element-plus";

axios.defaults.timeout = 10000;
//post请求头
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
// axios.defaults.withCredentials = true;
// 环境的切换,vue-cli3 开发就是dev,打包就是production
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = "https://tecs.dreamount.cn/";
} else if (process.env.NODE_ENV == "production") {
  axios.defaults.baseURL = "https://tecs.dreamount.cn/"; //https://tecsapi.marsontec.com
}

//请求拦截
axios.interceptors.request.use(
  (config) => {
    const userInfo = storage.getStorage("userInfo") || null;
    const token = (userInfo && userInfo.token) || null;
    if (token) {
      config.headers.Authorization = "Bearer " + token;
      config.headers.common["token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    console.log(response);
    return Promise.resolve({
      ...response,
      data: {
        data: response.data,
        stime: response.headers.stime,
        success: response.status == 200,
      },
    });
  },
  // 服务器状态码不是200的情况
  (error) => {
    console.log("错误处理");
    //状态码错误处理
    if (error.response.status == "401") {
      ElMessageBox({
        title: "登录失效",
        message: error.response.data.message,
        center: true,
        confirmButtonText: "重新登录",
      }).then(() => {
        localStorage.removeItem("userInfo");
        router.push({
          path: "/login",
        });
      });
      // appContext.config.globalProperties.$hello('jay');
    }
    return Promise.reject(error.response);
  }
);

//get请求封装
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

//post请求封装,
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

//post请求封装
export function postWithFile(url, params) {
  let config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

//delete方法的删除参数有点问题{ data: params }
export function deletes(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { data: params })
      .then((res) => {
        resolve(res.data);
        console.log("成功删除");
      })
      .catch((err) => {
        reject(err.data);
        console.log("删除失败");
      });
  });
}
const http = {
  get,
  post,
  postWithFile,
  put,
  deletes,
};
export default {
  install: (app) => {
    app.config.globalProperties["$https"] = http;
  },
};
