// import { codeString, md5String } from "./base64";
const isBrowser = true;
//))
export function getStorage(key, type = "local") {
  if (isBrowser) {
    const value = window[type + "Storage"].getItem(key);
    return JSON.parse(value);
  }
  return "";
}

export function setStorage(params, type = "local") {
  if (isBrowser) {
    const kv = Array.isArray(params) ? params : [params];
    for (const { key, value } of kv) {
      window[type + "Storage"].setItem(key, JSON.stringify(value));
    }
  }
}
export function removeStorage(params, type = "local") {
  if (isBrowser) {
    const kv = Array.isArray(params) ? params : [params];
    for (const key of kv) {
      window[type + "Storage"].removeItem(key);
    }
  }
}
export function setCookie(name, value, iDay) {
  var oDate = new Date();
  oDate.setDate(oDate.getDate() + iDay);
  document.cookie = name + "=" + value + ";expires=" + oDate;
}
/*****获取cookie*****/
export function getCookie(name) {
  var arr = document.cookie.split("; ");
  for (var i = 0; i < arr.length; i++) {
    var arr2 = arr[i].split("=");
    if (arr2[0] == name) {
      return arr2[1];
    }
  }
  return "";
}
/*****移除cookie*****/
export function removeCookie(name) {
  setCookie(name, 1, -1);
}
export const domainName = "https://tecsf.marsuntec.com/";
export default {
  getStorage,
  setStorage,
  removeStorage,
  setCookie,
  getCookie,
  removeCookie,
};
